@media (max-width: 600px) {
  .header {
  }

  .coverImageHeader {
    width: 100px;
    height: 100px;
  }

  .titleHeader {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .descriptionHeader {
    font-size: 10px;
  }

  .itemCover {
    width: 80px;
    height: 80px;
  }

  .itemTitle {
    font-size: 10px;
  }

  .itemSubTitle {
    font-size: 8px;
  }
}

@media (min-width: 601px) {
  .coverImageHeader {
    width: 250px;
    height: 250px;
  }

  .titleHeader {
    font-size: 28px !important;
    margin-bottom: 2px;
  }

  .itemCover {
    width: 200px;
    height: 200px;
  }

  .itemTitle {
    font-size: 15px !important;
    padding-top: 1;
  }

  .itemTitle:hover {
    color: red;
  }

  .itemSubTitle {
    font-size: 12px !important;
  }
}
