body {
  background-color: #212121 !important;
}

a {
  color: rgb(114, 35, 142);
}

.podcastPageHeader {
  min-height: 720px;
  height: 90vh;
}

.podcastPageHeaderBg {
  position: absolute;
  background-size: cover;
  background-position: center;
  min-height: 720px;
  width: 100%;
  filter: blur(20px);
  z-index: -1;
  opacity: 0.7;
}

.podcastPageCover {
  width: 250px;
  height: 250px;
}

.podcastPageTitle {
  font-weight: 400;
  font-size: 18px !important;
}

.podcastPageFeedTitle {
  font-weight: 700;
  font-size: 35px !important;
  text-align: center;
}

.podcastPageBody {
}

.podcastPageBodyDescriptionBox {
  width: 620px;
}

.podcastPageBodyDescriptionTitle {
  font-size: 14px !important;
}

.podcastPageBodyDescription {
  font-size: 14px !important;
}

@media screen and (max-width: 600px) {
  .podcastPageHeader {
    padding: 20px;
    min-height: 500px;
    height: 500px;
  }

  .podcastPageHeaderBg {
    position: absolute;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    height: 500px;
    width: 100%;
    filter: blur(20px);
    z-index: -1;
  }

  .podcastPageCover {
    width: 125px;
    height: 125px;
  }

  .podcastPageTitle {
    font-weight: 400;
    font-size: 18px !important;
  }

  .podcastPageFeedTitle {
    font-weight: 700;
    font-size: 26px !important;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .podcastPageBodyDescriptionBox {
    width: 90%;
  }

  .podcastPageBody {
    padding: 40px;
  }

  .podcastPageBodyDescriptionTitle {
    font-size: 14px !important;
  }

  .podcastPageBodyDescription {
    font-size: 14px !important;
  }
}
