::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  height: 60px;
  border: 4px solid rgba(255, 0, 0, 1);
  background-clip: padding-box;
  background-color: rgb(255, 0, 0);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgb(255, 0, 1),
    inset 1px 1px 0px rgb(255, 0, 0);
}
/* 
::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
} */
/* 
::-webkit-scrollbar-corner {
  background-color: transparent;
} */
