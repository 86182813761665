@keyframes wipe-in-up {
  from {
    clip-path: inset(100% 0 0 0);
    background-color: grey;
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:wipe:up"] {
  animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-up both;
}

@keyframes wipe-out-down {
  from {
    clip-path: inset(0 0 100% 0);
    background-color: grey;
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="out:wipe:down"] {
  animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-out-down both;
}

@keyframes diamond-in-center {
  from {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
  to {
    clip-path: polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%);
  }
}

[transition-style="in:diamond:center"] {
  animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) diamond-in-center both;
}

@keyframes square-in-top-right {
  from {
    clip-path: inset(100% 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:square:top-right"] {
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) square-in-top-right both;
}
